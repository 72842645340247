import React from 'react'

import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
import TopHeader from '../components/Common/TopHeader'
import Navbar from '../components/_App/Navbar'
import TeamArea from '../components/Team/TeamArea'
import Footer from '../components/_App/Footer'
import AboutArea from '../components/Index/AboutArea'

const TeamPage = () => {
  return (
    <Layout>
      <Seo title="Our Team" description="Get to Know Us" />

      <TopHeader />

      <Navbar />

      <AboutArea />

      <TeamArea />

      <Footer />
    </Layout>
  )
}

export default TeamPage
